import React from 'react';

import "./zettaDark.scss"

const ZettaDark = () => {
  return (
    <>
      <div className="zetta-dark">zetta dark</div>
    </>
  )
};

export default ZettaDark;
